// import logo from './logo.svg';
import './App.css';
import {Button, message, Upload} from "antd";
import {DownloadOutlined, InboxOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';
import {useState} from "react";
import ReactCompareImage from 'react-compare-image';
import * as path from "path";

// const Compress = require('compress.js')
// function getBase64(img, callback) {
//     const reader = new FileReader();
//     reader.addEventListener('load', () => callback(reader.result));
//     reader.readAsDataURL(img);
// }

// async function resizeImageFn(file) {
//
//     const resizedImage = await compress.compress([file], {
//         size: 2, // the max size in MB, defaults to 2MB
//         quality: 1, // the quality of the image, max is 1,
//         maxWidth: 300, // the max width of the output image, defaults to 1920px
//         maxHeight: 300, // the max height of the output image, defaults to 1920px
//         resize: true // defaults to true, set false if you do not want to resize the image width and height
//     })
//     const img = resizedImage[0];
//     const base64str = img.data
//     const imgExt = img.ext
//     const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt)
//     return resizedFiile;
// }

const { Dragger } = Upload;

function App() {
    const props = {
        name: 'file',
        //action: 'http://localhost:3232/upload',
        action: 'https://7574-213-108-43-4.ngrok.io/upload',
        // headers: {
        //     authorization: 'authorization-text',
        // },
        beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            console.log('File:', file)
            // document.getElementById('testimage').src = URL.createObjectURL(file);
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;




    },
        onChange(info) {

            if (info.file.status !== 'uploading') {
                console.log('1st if', info.file.originFileObj);
                console.log(info.file.originFileObj)
                //console.log('Response----:', info.file.response);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
                console.log(info.file)
                // setImagePng("https://e362-213-108-43-4.ngrok.io/Images/"+info.file.response.imageRes)
                // setImageJpg("https://e362-213-108-43-4.ngrok.io/Images/"+info.file.response.imageInit)
                setImagePng('data:image/png;base64,' + info.file.response)
                // setImageJpg('data:image/png;base64,' + info.file.response)
                setImageJpg(URL.createObjectURL(info.file.originFileObj))
                setImageName( path.basename(info.file.name, path.extname(info.file.name))+'-noBackground.png');

                // document.getElementById('testimage').src = 'data:'+info.file.type+';base64,' + info.file.response;
                // console.log(info.file.response)
                //console.log('ccccccccc', convertToImage(info.file.response))
                // console.log(image1)

            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };
    const [imagePng, setImagePng] = useState('data:image/png;base64')
    const [imageJpg, setImageJpg] = useState('data:image/png;base64')
    const [imageName, setImageName] = useState('noBackground.png')

  return (
    <div className="App">

        <Dragger {...props}>
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">Drag and drop your image here</p>
            <p className="ant-upload-hint">
                JPG or PNG
            </p>
        </Dragger>

      {/*<Upload {...props}>*/}
      {/*  <Button icon={<UploadOutlined />}>Upload image</Button>*/}
      {/*</Upload>*/}

        <div className="Image-Block">
            {/*<div className="Image-Block"> .</div>*/}
            {/*<div style={{ maxWidth: '200px'}}>*/}
        {/*<div className="bkgTransparent">*/}
            <ReactCompareImage
                leftImage={imageJpg}
                rightImage={imagePng}
                // rightImageCss={{backgroundImage: "/src/components/RmjwQiJorKyobvI.jpg"}}/>
                rightImageCss={{backgroundImage: "url(//zos.alipayobjects.com/rmsportal/RmjwQiJorKyobvI.jpg)"}}/>
            {/*</div>*/}
            <div className="card-text">
                <Button download={imageName} href={imagePng} type="primary" shape="round" icon={<DownloadOutlined />} size={'large'} >Download</Button>
                <p style={{color: 'red'}}>For now Download is not working</p>
            </div>
        </div>
    </div>
  );
}

export default App;
